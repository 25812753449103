import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { initializeApp as initFirebase } from 'firebase/app';
import { ToastContainer } from 'react-toastify';

import Analytics from '@web-solutions/module-analytics';
import Attribution from '@web-solutions/module-attribution';

import { ThemeContextProvider } from 'core/hooks/use-theme-context';
import { Theme } from 'core/constants/theme';

import { isManage, isTerminate } from 'core/utils/url-sync';

import { getMagnusToken } from 'src/store/app/utils';

import { APP_LINKS } from 'src/constants/general';

import packageInfo from '../package.json';

import configureStore from './store';
import { loadRemoteConfig, load } from './store/app/actions';
import { initLocalization } from './localization';

import 'react-toastify/dist/ReactToastify.min.css';
import 'src/styles/global.scss';
import 'src/styles/index.scss';
import 'src/styles/variables.css';

const App = (AppRouter: React.FC) => {
  const pType = window.location.pathname.split('/')[1];
  const { store, persistor } = configureStore();

  if (pType !== 'terminate') {
    store.dispatch(load());
  } else {
    store.dispatch(loadRemoteConfig());
  }

  return (
    <StrictMode>
      <Provider store={store}>
        <ThemeContextProvider value={{ theme: Theme.light }}>
          <PersistGate loading={null} persistor={persistor}>
            <AppRouter />
            <ToastContainer position="bottom-center" autoClose={5000} closeOnClick />
          </PersistGate>
        </ThemeContextProvider>
      </Provider>
    </StrictMode>
  );
};

initFirebase({
  apiKey: 'AIzaSyCg_-zDakcWJgL0HRYuFoxrxPJIYB0AjaQ',
  authDomain: 'reflectio-59809.firebaseapp.com',
  projectId: 'reflectio-59809',
  storageBucket: 'reflectio-59809.appspot.com',
  messagingSenderId: '622365671179',
  appId: '1:622365671179:web:db7668fb60b0f0badaec0e',
  measurementId: 'G-CE3HNDPP9L',
});

const init = async () => {
  initLocalization();
  Analytics.preinit(getMagnusToken(), packageInfo.version);
  await Attribution.init(APP_LINKS, { fetchPrevInstall: isManage() || isTerminate() });
  Analytics.init(getMagnusToken(), packageInfo.version);
}

export const render = (AppRouter: React.FC) => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  init().then(() => {
    root.render(App(AppRouter));
  })
};